<template>
  <div class="rules-manager">
    <!-- 面包屑 -->
    <els-bread class="_bread"></els-bread>
    <!-- header -->
    <div class="_header rm--header">
      <els-button-group type="primary" size="small" :column="buttons"></els-button-group>
      <div class="h--search">
        <el-input v-model="searchValue" prefix-icon="el-icon-search" :placeholder="lang.inputRuleName" size="small"></el-input>
        <!-- <el-divider direction="vertical"></el-divider>
        <el-button icon="el-icon-search" type="primary" @click="reloadTable" size="small">查询</el-button>
        <el-button icon="el-icon-refresh" @click="handleResetTable" type="success" size="small">重置</el-button> -->
      </div>

    </div>
    <!-- content -->
    <div class="_content rm--content">
      <els-table ref="elsTableRef" :column="column" :onload="onload" menuWidth="310px">
        <template v-slot:menu="{row}">
          <els-button-group type="text" :column="tableMenus" delimiter="divider" :params="row"></els-button-group>
        </template>
      </els-table>
      <rule-design :visible.sync="drawerVisible" :nodes="nodes" :getEventList="getEventsData" :metadata="currentMetadata" :currentRow="currentRow" @save="handleSaveGraph"></rule-design>
    </div>
    <!-- dialog -->
    <els-form-dialog :visible.sync="dialogVisible" :title="dialogTitle" @submit="handleSubmit" :cancel="lang.cancel" :submit="lang.confirm">
        <els-form v-if="dialogVisible" ref="elsFormRef" v-model="formData" :column="formColumn" :ctx="{lang: $t('d')}"></els-form>
    </els-form-dialog>
    <!-- 导入文件 -->
    <el-dialog :visible.sync="importVisible" :title="lang.importRule" width="400px">
      <el-upload drag action='' :auto-upload="false" :on-change="handleUploadOnChange" :show-file-list="false">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">{{lang.dragJSON}}<em>{{lang.clickImport}}</em></div>
      </el-upload>
    </el-dialog>
  </div>
</template>


<script>
import { saveAs } from 'file-saver';
//
import elsBread from "@/components/els-breadcrumb/main.vue"
import elsTable from "@/components/els-table/main.vue";
import elsDrawer from "@/components/els-drawer/main.vue"
import elsFormDialog from "@/components/els-form-dialog/main.vue";
import elsForm from "@/components/els-form/main.vue";
import ruleDesign from "../components/rulesDesign/index"
//
import { mapState,mapGetters } from "vuex";
// api
import {getList,addRule,deleteRule,getNodes,getEventsData,getMetadata,saveMetadata,resetRootRuleChain} from "@/api/manager/rules-manager.js"
import mixinsI18n from "@/mixins/i18n.js"
import { downloadBlob } from "@/utils/util"

export default {
  components:{elsTable,elsDrawer,elsBread,elsFormDialog,elsForm,ruleDesign},
  mixins: [mixinsI18n],
  data:() => ({
    PAGE_NAME: "ruleManagePage",
    searchValue:'',
    drawerVisible:false,
    dialogVisible:false,
    importVisible:false,
    dialogTitle:'',
    formData:{},
    nodes:undefined,
    currentRow:undefined,
    currentMetadata:undefined,
  }),
  computed:{
    ...mapState(["breadMenus","userInfo"]),
    ...mapGetters({ruleList:'ruleDesign/ruleList',ruleNodes:'ruleDesign/ruleNodes',ruleForm:'ruleDesign/ruleForm'}),
    buttons(){
      const add = {label:this.lang.addRule,handle:this.handleAdd}
      const inp = {label:this.lang.importRule,handle:this.handleImport}
      return [add,inp]
    },
    column(){
      const dict = [{label:this.lang.yes,value:true},{label:this.lang.no,value:false}]
      const name = {prop:'name',label:this.lang.ruleName,minWidth: 180}
      const isRoot = {prop:'root',label:this.lang.isRootRule,dict}
      const isTest = {prop:'debugMode',label:this.lang.isDebugMode,dict,minWidth: 100}
      const desc = {type:'textarea',prop:'desc',label:this.lang.describe,minWidth: 100}
      const createTime = {prop:'createdTime',label:this.lang.createTime,valueType:'date',minWidth: 160}
      // const editTime = {prop:'',label:'最后编辑时间'}
      return [name,isRoot,isTest,desc,createTime]
    },
    tableMenus(){
      // 查看 设计 删除 导出
      const view = {label:this.lang.view,handle:this.handleView}
      const root = {label:this.lang.setRoot,handle:this.handleSetRoot,':disabled':'params.root'}
      const edit = {label:this.lang.edit,handle:this.handleEdit}
      const design = {label:this.lang.design,handle:this.handleDesign}
      const del = {label:this.lang.delete,handle:this.handleDelete,':disabled':'params.root'}
      const out = {label:this.lang.export,handle:this.handleOutput}
      return [root,edit,design,del,out]
    },
    formColumn(){
      const name = {prop:'name',label:this.lang.name,required:true,rules:[ { required: true, message: this.lang.inputName, trigger: 'blur' },{max:15,min:1,message: this.lang.max15}]}
      // const root = {tag:'el-checkbox',prop:'root',props:{label:'是否是根规则链'},value:false}
      const debug = {tag:'el-checkbox',prop:'debugMode',props:{label:this.lang.enableDebug},value:false}
      const desc = {prop:'additionalInfo.description',label:this.lang.describe}
      return [name,debug,desc]
    },
    graphData(){
      const row = this.currentRow || {}
      const additionalInfo = row.additionalInfo || {}
      const source = additionalInfo.source
      if(source){
        return JSON.parse(source)
      }
      return [{nodes:[],edges:[]},{}];
    },
  },
  methods:{
    async onload(page,query){
      try{
        // const pn = page.pageNum;
        // const ps = page.pageSize;
        // const row = page.row;
        const p = {pageNum:page.pageNum,pageSize:page.pageSize,limit:page.pageSize,textSearch:this.searchValue}
        // if(pn > 1 && row){
        //   p.idOffset = row.id;
        // }
        const res = await getList(p)
        if(res.data){
          const total = res.totalCount || res.data.length;
          return {list:res.data.map(item => ({...item,id:item.id.id,sourceId:item.id,desc:item.additionalInfo?item.additionalInfo.description:''})),total}
        }
      }
      catch(e){console.error(e)}
      return {list:[],total:0}
    },
    //
    async addRule(data = {}){

      try{

        const res = await addRule(data)
        if(res){
          this.$message.success(this.dialogTitle + this.lang.success)
          this.dialogVisible = false;
          //
          this.reloadTable();
          return res;
        }
      }
      catch(e){console.error(e)}
    },
    //
    async deleteRule(id){
      try{
        await deleteRule(id)
        this.$message.success(this.lang.delSuccess)
        this.reloadTable();
      }
      catch(e){console.log(e)}
    },
    async getNodes(){
      if(this.ruleList && this.ruleForm && this.ruleNodes) { return ;}
      try{
        const res = await getNodes()
        this.$store.commit('ruleDesign/setNodes',res);
        this.nodes = res;
        // console.log(this.$store)
      }
      catch(e){console.log(e)}
    },
    async getEventsData(id,name,params){
      try{
        const tenantId = this.userInfo?.tenantId?.id;
        const res = await getEventsData(id,name,{...params,tenantId})
        if(res.data){
          return res.data.map(d => ({id:d.id.id,createdTime:d.createdTime,...d.body}))
        }
        return []
      }catch(e){console.log(e)}
    },
    async getMetadata(){
      try{
        const id = this.currentRow.id;
        const res = await getMetadata(id);
        this.currentMetadata = res;
      }catch(e){console.log(e)}
      return undefined;
    },
    async saveMetadata(data = {}){
      try{
        const res = await saveMetadata(data)
        if(res){
          this.$message.success(this.lang.saveSuccess)
          return res;
        }
      }catch(e){console.log(e)}
    },
    async resetRootRuleChain(id){
      try {
        await resetRootRuleChain(id)
        this.reloadTable();
        this.$message.success(this.lang.resetRoot)
      } catch (error) {
        console.log(error)
      }
    },
    //
    async saveImportData(ruleChain,metadata){
      try{
        const res = await this.addRule(ruleChain);
        const ruleChainId = res.id;
        await this.saveMetadata({...metadata,ruleChainId})
        //
        // this.$message.success('导入成功！')
        //
        this.importVisible = false;
      }
      catch(e){console.log(e)}
    },
    //
    handleResetTable(){
      this.searchValue = '';
      this.reloadTable();
    },
    //
    handleAdd(){
      this.dialogTitle = this.lang.addRule
      this.dialogVisible = true;
    },
    handleImport(){
      this.importVisible = true;
    },
    //
    handleUploadOnChange(file){
      const _this = this;
      var reader = new FileReader();
      reader.readAsText(file.raw);
      reader.onload = function() {
        const str = this.result;
        if(str){
          try{
            const {ruleChain,metadata} = JSON.parse(str);
            const bool = _this.checkRuleData(ruleChain,metadata)
            if(bool){
              _this.saveImportData(ruleChain,metadata)
            }else{
              console.log('check error',ruleChain,metadata)
              _this.$message.error(this.lang.fileFailCheck)
            }
          }catch(e){
             _this.$message.error(this.lang.JSONFailCheck)
          }

        }
      }

    },
    async handleSubmit(){
      if(!this.$refs.elsFormRef) return;
      try{
        await this.$refs.elsFormRef.validate();
        const d = this.formData;
        this.addRule(d);
      }catch(e){console.log(e)}

    },
    //
    handleView(d){
      this.currentRow = d.params;
      //
      this.drawerVisible = true;

    },
    handleEdit(d){
      this.currentRow = d.params;
      const row = d.params;
      const {name,additionalInfo,debugMode,sourceId:id,tenantId,root} = row;
      this.formData = {name,additionalInfo,debugMode,id,tenantId,root}
      this.dialogTitle = this.lang.editRule
      this.dialogVisible = true;
    },
    handleSetRoot(d){
      const {id,root} = d.params
      if(root){
        this.$message.warning(this.lang.isRuleChain)
        return ;
      }
      this.resetRootRuleChain(id);
    },
    handleDesign(d){
      this.currentRow = d.params;
      const {id,name,root} = this.currentRow;
      this.$router.push({name:'ruleDesignPage',params:{id,name,root},query:{id,name,root}})
      // this.drawerVisible = true;
      // this.getMetadata();
    },
    handleDelete(d){
      this.currentRow = d.params;
      const row = d.params;
      const id = row.id;
      // if(row.root){
      //   this.$message.warning('根规则链不可删除')
      // }
      this.$confirm(this.lang.delMsg, this.lang.tips, {
        confirmButtonText: this.lang.confirm,
        cancelButtonText: this.lang.cancel,
          type: 'warning'
        }).then(() => {
          this.deleteRule(id)
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
    });
    },
    async handleOutput(d){
      this.currentRow = d.params;
      try{
        await this.getMetadata();
        const r = this.currentRow;
        const m = this.currentMetadata;
        //
        const {additionalInfo,name,firstRuleNodeId,root,debugMode,configuration} = r;
        const {firstNodeIndex,nodes,connections,ruleChainConnections} = m;
        //
        const ruleChain = {additionalInfo,name,firstRuleNodeId,root,debugMode,configuration}
        const metadata = {firstNodeIndex,connections,ruleChainConnections,nodes:nodes.map(({additionalInfo,type,name,debugMode,configuration}) => ({additionalInfo,type,name,debugMode,configuration}))}
        //
        const data = {ruleChain,metadata}
        //
        downloadBlob(new Blob([JSON.stringify(data)]),name + '.json')
        //
        // var file = new File([JSON.stringify(data)], "__.json", {type: "text/plain;charset=utf-8"});
        // saveAs(file)
      }catch(e){console.log(e)}
    },
    //
    handleSaveGraph(data,source){
      this.saveMetadata(data)
      // try{
      //   // const res = await this.saveMetadata(data)
      //   // let fid = undefined;
      //   // if(res){
      //   //   const {nodes,firstNodeIndex} = res;
      //   //   const keys = {}
      //   //   nodes.forEach((n,ins) => {
      //   //     const {additionalInfo,createdTime,id,ruleChainId} = n
      //   //     const k = additionalInfo.layoutX + '.' + additionalInfo.layoutY
      //   //     keys[k] = {id}
      //   //     if(firstNodeIndex == ins){fid = id}
      //   //   })
      //   //   const [{ nodes:nds, edges}, form] = source;
      //   //   const ns = nds.map(n => {
      //   //     const k = n.x + '.' + n.y;
      //   //     const key = keys[k]
      //   //     if(key){
      //   //       return {...n,key}
      //   //     }
      //   //     return n;
      //   //   });
      //   //   console.log(keys)
      //   //   source = [{nodes:ns,edges},form]
      //   // }
      //   // source = JSON.stringify(source)
      //   // //
      //   // console.log('.....',source)
      //   // let {additionalInfo = {},firstRuleNodeId,sourceId} = this.currentRow;
      //   // if(!additionalInfo){additionalInfo = {source}}
      //   // else{additionalInfo.source = source}
      //   // //
      //   // if(fid){firstRuleNodeId = fid}
      //   //
      //   // this.addRule({...this.currentRow,id:sourceId,additionalInfo,firstRuleNodeId})
      // }catch(e){console.log(e)}
    },
    //
    checkRuleData(ruleChain,metadata){
      let bool = false;
      if(ruleChain){
        // ruleChain 校验
        const {name,root} = ruleChain;
        if(name != undefined && typeof root == 'boolean'){
          bool = true;
        }
      }

      return bool;
    },
    //
    reloadTable(){
      this.$refs.elsTableRef && this.$refs.elsTableRef.apiOnload();
    }
  },
  watch:{
    searchValue(){
      this.reloadTable();
    },
    dialogVisible(v){
      if(!v){
        this.formData = {};
      }
    }
  },
  created(){
    this.getNodes();
  }
}
</script>

<style lang="scss" scoped>
.rules-manager{
  width:100%;
  height:100%;
  padding:0 24px 24px 24px;
  box-sizing: border-box;
  .rm--bread{
    height:44px;
    display: flex;
    align-items: center;
  }
  .rm--header{
    height:44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    >.el-input{width:200px;}
    .h--search{
      display:flex;
      align-items: center;
    }
  }
  .rm--content{
    position: relative;
    width:100%;
    height:calc(100% - 44px - 44px);
  }
}
</style>
